/* You can add global styles to this file, and also import other style files */
/*
@File: Zico Template Style

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Home One CSS
** - Home Two CSS
** - Home Three CSS
** - About CSS
** - About Me CSS
** - Work Details CSS
** - Video Details CSS
** - Blog Details CSS
** - Partner CSS
** - Pricing CSS
** - FAQ CSS
** - Error CSS
** - Coming Soon CSS
** - Privacy Policy CSS
** - Contact CSS
** - Preloader CSS
** - Back to Top CSS
*/
/*-- Home One --*/
/*-- Default CSS --*/
body {
  font-family: "Playfair Display", serif;
  color: #d5d7d7;
  background-color: #0c0c0c;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  font-family: "Josefin Sans", sans-serif;
}

p {
  line-height: 1.8;
}

a {
  transition: 0.5s all ease;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

button:focus {
  outline: 0;
}

.btn.focus, .btn:focus {
  box-shadow: none;
}

.container {
  max-width: 1270px;
}

/*-- End Default CSS --*/
/*-- Navbar --*/
.main-nav {
  background: rgba(0, 0, 0, 0.7215686275);
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 0.5s all ease;
}
.main-nav .container {
  max-width: 1620px;
}
.main-nav.menu-shrink {
  background-color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
}
.main-nav nav .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.main-nav nav .navbar-nav.active > .nav-link, .main-nav nav .navbar-nav .nav-link.active, .main-nav nav .navbar-nav .nav-link.show, .main-nav nav .navbar-nav.show > .nav-link {
  color: #c1ab65;
}
.main-nav nav .navbar-nav .nav-item i {
  display: inline-block;
  font-size: 20px;
  top: 4px;
  right: 2px;
  position: relative;
}
.main-nav nav .navbar-nav .nav-item:hover a {
  color: #c1ab65;
}
.main-nav nav .navbar-nav .nav-item a {
  font-weight: 600;
  font-size: 17px;
  transition: 0.5s all ease;
  font-family: "Josefin Sans", sans-serif;
  text-transform: capitalize;
}
.main-nav nav .navbar-nav .nav-item a:hover, .main-nav nav .navbar-nav .nav-item a:focus, .main-nav nav .navbar-nav .nav-item a.active {
  color: #c1ab65;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu {
  background: #ffffff;
  padding-top: 5px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 5px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li {
  border-bottom: 1px dashed #b8b5b5;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:last-child {
  border-bottom: 0;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  font-size: 16px;
  color: #01091e;
  position: relative;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  top: 19px;
  left: 0;
  background-color: #c1ab65;
  opacity: 0;
  transition: 0.5s all ease;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover, .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus, .main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #c1ab65;
  padding-left: 30px;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover:before, .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus:before, .main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active:before {
  opacity: 1;
  width: 25px;
}
.main-nav .dropdown-toggle::after {
  display: none;
}

.side-nav .modal-btn {
  padding: 0;
  transform: rotate(180deg);
  position: relative;
  top: -6px;
  background-color: transparent;
  border: 0;
}
.side-nav .modal-btn:hover span:nth-child(2) {
  width: 25px;
}
.side-nav .modal-btn:hover span:nth-child(3) {
  width: 15px;
}
.side-nav .modal-btn span {
  display: block;
  width: 35px;
  height: 3px;
  background-color: #ffffff;
  margin-bottom: 5px;
  transition: 0.5s all ease;
}

.modal-body {
  padding: 40px 30px 50px;
}
.modal-body h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #000;
}
.modal-body p {
  color: #545555;
  font-size: 14px;
  margin-bottom: 20px;
}
.modal-body .image-area {
  margin-bottom: 10px;
}
.modal-body .image-area .col-lg-4 {
  padding-right: 5px;
  margin-right: -5px;
}
.modal-body .image-area a {
  display: block;
  margin-bottom: 15px;
}
.modal-body .image-area a img {
  width: 100%;
}
.modal-body .modal-item {
  margin-bottom: 30px;
}
.modal-body .social-area {
  text-align: center;
}
.modal-body .social-area h3 {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #000;
}
.modal-body .social-area ul {
  margin: 0;
  padding: 0;
}
.modal-body .social-area ul li {
  list-style-type: none;
  display: inline-block;
}
.modal-body .social-area ul li a {
  display: block;
  color: #c1ab65;
  border: 1px solid #c1ab65;
  width: 32px;
  height: 32px;
  line-height: 34px;
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 16px;
  text-align: center;
}
.modal-body .social-area ul li a:hover {
  color: #ffffff;
  background-color: #c1ab65;
}

#myModalRight {
  z-index: 99999;
}
#myModalRight .modal-header .close {
  position: relative;
  top: 5px;
  color: #000;
  font-weight: bold;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 35px;
}

.modal.modal-right .modal-dialog {
  max-width: 380px;
  min-height: 100vh;
}
.modal.modal-right.show .modal-dialog {
  transform: translate(0, 0);
}
.modal.modal-right .modal-content {
  height: 100vh;
  overflow-y: auto;
  border-radius: 0;
}
.modal.modal-left .modal-dialog {
  transform: translate(-100%, 0);
  margin: 0 auto 0 0;
}
.modal.modal-right .modal-dialog {
  transform: translate(100%, 0);
  margin: 0 0 0 auto;
}

.search-icon {
  font-size: 22px;
  position: relative;
  top: 1px;
  color: #ffffff;
  border: 0;
  outline: none;
  transition: 0.5s all ease;
  background-color: transparent;
}
.search-icon:hover {
  color: #c1ab65;
}

.search-toggle .search-icon.icon-close {
  display: none;
}

.search-toggle.opened .search-icon.icon-search {
  display: none;
}

.search-toggle.opened .search-icon.icon-close {
  display: block;
}

.nav-srh {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  top: 3px;
}
.nav-srh .search-area {
  position: absolute;
  right: 8px;
  bottom: -105px;
  z-index: 5;
  transition: 0.5s all ease;
  max-height: 0;
  overflow: hidden;
  background-color: #ffffff;
}
.nav-srh .search-area .search-icon {
  vertical-align: middle;
  position: absolute;
  right: 30px;
  top: 28px;
  background-color: transparent;
  font-size: 20px;
  color: #515151;
  transition: 0.5s all ease;
}
.nav-srh .search-area .search-icon:hover {
  color: #c1ab65;
}
.nav-srh .search-area .src-input {
  outline: none;
  padding-left: 20px;
  margin: 0;
  width: 300px;
  background-color: rgba(221, 221, 221, 0.5019607843);
  border: 1px solid #ddd;
  font-size: 16px;
  height: 45px;
  border-radius: 0;
}
.nav-srh .search-area.opened {
  max-height: 100px;
  padding: 20px 25px;
  box-shadow: 0px 0px 15px rgba(221, 221, 221, 0.5019607843);
}

/*-- End Navbar --*/
/*-- Banner --*/
.banner-img-one {
  background-image: url("assets/img/home-one/banner-main1.jpg");
}

.banner-img-two {
  background-image: url("assets/img/home-one/banner-main2.jpg");
}

.banner-img-three {
  background-image: url("assets/img/home-one/banner-main3.jpg");
}

.banner-wrap {
  position: relative;
}

.banner-social {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: rotate(-90deg);
}
.banner-social span {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
}
.banner-social ul {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.banner-social ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 8px;
}
.banner-social ul li:last-child {
  margin-right: 0;
}
.banner-social ul li a {
  display: block;
  color: #ffffff;
  font-size: 16px;
}
.banner-social ul li a:hover {
  color: #c1ab65;
}

.banner-area {
  height: 1000px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
}
.banner-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
}

.banner-content {
  position: relative;
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}
.banner-content h1 {
  font-weight: 700;
  font-size: 100px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.banner-content p {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 40px;
}
.banner-content .banner-btn {
  font-weight: 600;
  color: #01091e;
  background-color: #ffffff;
  border-radius: 5px;
  display: inline-block;
  padding: 12px 25px 9px;
  position: relative;
  z-index: 1;
  font-family: "Josefin Sans", sans-serif;
}
.banner-content .banner-btn:before {
  position: absolute;
  content: "";
  width: 0%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #01091e;
  opacity: 0;
  z-index: -1;
  transition: 0.5s all ease;
  border-radius: 5px;
}
.banner-content .banner-btn:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  background-color: #01091e;
  opacity: 0;
  z-index: -1;
  transition: 0.5s all ease;
  border-radius: 5px;
}
.banner-content .banner-btn:hover {
  color: #ffffff;
}
.banner-content .banner-btn:hover:before {
  height: 100%;
  width: 100%;
  opacity: 1;
}
.banner-content .banner-btn:hover:after {
  height: 100%;
  width: 100%;
  opacity: 1;
}

.banner-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 35px;
}
.banner-slider.owl-theme .owl-dots .owl-dot span {
  width: 14px;
  height: 14px;
  background: #b8b7b7;
}
.banner-slider.owl-theme .owl-dots .owl-dot.active span {
  background: #869791;
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  background: transparent;
  position: relative;
  margin: 1px;
}
.banner-slider.owl-theme .owl-dots .owl-dot.active span:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: 0;
  right: 0;
  bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
}

.lines {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: 90vw;
}
.lines .line {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  left: 50%;
  background: hsla(0, 0%, 100%, 0.1);
  overflow: hidden;
  z-index: 1;
}
.lines .line:nth-child(1) {
  margin-left: -12.5%;
}
.lines .line:nth-child(2) {
  margin-left: -25%;
}
.lines .line:nth-child(3) {
  margin-left: -37.5%;
}
.lines .line:nth-child(4) {
  margin-left: -49%;
}
.lines .line:nth-child(5) {
  margin-left: 0%;
}
.lines .line:nth-child(6) {
  margin-left: 12.5%;
}
.lines .line:nth-child(7) {
  margin-left: 25%;
}
.lines .line:nth-child(8) {
  margin-left: 37.5%;
}
.lines .line:nth-child(9) {
  margin-left: 49.5%;
}
.lines .line:after {
  content: "";
  display: block;
  position: absolute;
  height: 10vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff 75%, #fff);
  animation: run 7s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

@keyframes run {
  0% {
    top: -50%;
  }
  to {
    top: 110%;
  }
}
/*-- End Banner --*/
/*-- Trust --*/
.trust-area {
  background-color: #080808;
}
.trust-area .section-title {
  margin-bottom: 25px;
}
.trust-area .trust-content {
  text-align: center;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}
.trust-area .trust-content p {
  margin-bottom: 40px;
}
.trust-area .trust-content .trust-img {
  margin-bottom: 30px;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  margin-top: -8px;
}
.section-title .sub-title {
  font-weight: 700;
  font-size: 17px;
  color: #c1ab65;
  display: inline-block;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.section-title h2 {
  font-weight: 600;
  font-size: 45px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0;
}

/*-- End Trust --*/
/*-- Week --*/
.week-video-btn a {
  top: 35% !important;
}

.week-video-btn-two a {
  top: 32% !important;
}

.week-area .week-item {
  position: relative;
  margin-bottom: 30px;
}
.week-area .week-item:hover a {
  opacity: 1;
}
.week-area .week-item img {
  width: 100%;
}
.week-area .week-item a {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  width: 70px;
  height: 70px;
  line-height: 70px;
  top: 43%;
  left: 0;
  right: 0;
  font-size: 40px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  background-color: #c1ab65;
  opacity: 0;
}
.week-area .week-item a i {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
.week-area .week-item a:hover {
  background-color: #01091e;
}
.week-area .owl-theme .owl-nav {
  margin-top: 0;
}
.week-area .owl-prev {
  position: absolute;
  top: 43%;
  left: 15px;
  width: 60px;
  height: 60px;
  font-size: 45px !important;
  line-height: 65px !important;
  border-radius: 50% !important;
  color: #b9b9b5 !important;
  background-color: #111111 !important;
  transition: 0.5s all ease;
}
.week-area .owl-prev:hover {
  color: #111111 !important;
  background-color: #ffffff !important;
}
.week-area .owl-next {
  position: absolute;
  top: 43%;
  right: 15px;
  width: 60px;
  height: 60px;
  font-size: 45px !important;
  line-height: 65px !important;
  border-radius: 50% !important;
  color: #b9b9b5 !important;
  background-color: #111111 !important;
  transition: 0.5s all ease;
}
.week-area .owl-next:hover {
  color: #111111 !important;
  background-color: #ffffff !important;
}

/*-- End Week --*/
/*-- Work --*/
.work-area a {
  display: block;
}

.work-item {
  position: relative;
  text-align: center;
  overflow: hidden;
}
.work-item:hover img {
  transform: scale(1.1);
}
.work-item:hover .work-content h3 {
  color: #c1ab65;
}
.work-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8057328985) 32%, rgba(0, 0, 0, 0) 75%);
  transition: 0.5s all ease;
  z-index: 1;
}
.work-item img {
  width: 100%;
  transition: 0.5s all ease;
}
.work-item .work-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  z-index: 1;
}
.work-item .work-content h3 {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 5px;
  text-transform: uppercase;
  color: #d4d0d0;
  transition: 0.5s all ease;
}
.work-item .work-content span {
  display: block;
  color: #d4d0d0;
  font-weight: 600;
  font-size: 24px;
  font-family: "Josefin Sans", sans-serif;
}

/*-- End Work --*/
/*-- History --*/
.history-area {
  background-image: url("assets/img/home-one/history-bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  margin: 0;
}
.history-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.9;
  display: block;
}
.history-area .cd-horizontal-timeline {
  margin: 0;
}
.history-area .container-fluid {
  max-width: 1450px;
}
.history-area .section-title {
  position: relative;
}
.history-area .history-img {
  position: relative;
  margin-bottom: 30px;
}
.history-area .history-img img {
  width: 100%;
}
.history-area .history-content {
  position: relative;
}
.history-area .history-content h2 {
  color: #ffffff;
  font-size: 48px;
  margin-bottom: 10px;
  font-weight: 400;
}
.history-area .history-content p {
  color: #d5d7d7;
  font-size: 17px;
  max-width: 715px;
  margin-bottom: 15px;
}
.history-area .history-content .history-inner {
  margin-bottom: 30px;
}
.history-area .history-content .history-inner i {
  display: inline-block;
  font-size: 40px;
  margin-bottom: 10px;
  color: #ffffff;
}
.history-area .history-content .history-inner h3 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
}
.history-area .history-content .history-inner span {
  display: block;
  color: #d5d7d7;
}
.history-area .timeline {
  width: unset;
  max-width: 100%;
  margin-bottom: 25px;
}
.history-area .timeline .filling-line {
  display: none;
}
.history-area .timeline .events-wrapper:before {
  display: none;
}
.history-area .timeline .events-wrapper:after {
  display: none;
}
.history-area .timeline .events-wrapper .events {
  background: #a5a5a5;
  width: 100% !important;
}
.history-area .timeline .events-wrapper .events ul {
  margin: 0;
  padding: 0;
}
.history-area .timeline .events-wrapper .events ul li {
  list-style-type: none;
  display: inline-block;
}
.history-area .timeline .events-wrapper .events ul li a {
  font-size: 24px;
  color: #ffffff;
}
.history-area .timeline .events-wrapper .events ul li a:before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  left: 0;
  right: 0;
  bottom: -2px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  z-index: 1;
}
.history-area .timeline .events-wrapper .events ul li a:after {
  background-color: #000;
  border: 1px solid #ffffff;
  width: 18px;
  height: 18px;
  bottom: -7px;
}
.history-area .timeline .events-wrapper .events ul li:nth-child(1) a {
  left: 17% !important;
}
.history-area .timeline .events-wrapper .events ul li:nth-child(2) a {
  left: 32% !important;
}
.history-area .timeline .events-wrapper .events ul li:nth-child(3) a {
  left: 50% !important;
}
.history-area .timeline .events-wrapper .events ul li:nth-child(4) a {
  left: 65% !important;
}
.history-area .timeline .events-wrapper .events ul li:nth-child(5) a {
  left: 80% !important;
}
.history-area .timeline .cd-timeline-navigation {
  margin: 0;
  padding: 0;
}
.history-area .timeline .cd-timeline-navigation li {
  list-style-type: none;
  display: inline-block;
  color: #ffffff;
  font-size: 30px;
}
.history-area .timeline .cd-timeline-navigation li:first-child i {
  position: absolute;
  top: 35px;
  left: 27px;
  display: inline-block;
}
.history-area .timeline .cd-timeline-navigation li:last-child i {
  position: absolute;
  top: 35px;
  right: 27px;
  display: inline-block;
}
.history-area .events-content {
  margin: 0;
}
.history-area .events-content ul {
  margin: 0;
  padding: 0;
}
.history-area .events-content ul li {
  list-style-type: none;
  display: block;
  padding: 0;
}

/*-- End History --*/
/*-- Video --*/
.video-area {
  position: relative;
}
.video-area .video-two:hover a {
  opacity: 1;
}
.video-area .video-two a {
  opacity: 0;
  top: 32%;
}

.video-item {
  position: relative;
}
.video-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8057328985) 32%, rgba(0, 0, 0, 0) 75%);
  opacity: 0.4;
}
.video-item a {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  width: 100px;
  height: 100px;
  line-height: 90px;
  top: 41%;
  left: 0;
  right: 0;
  font-size: 65px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  border: 5px solid #ffffff;
}
.video-item a i {
  width: 100px;
  height: 100px;
  line-height: 90px;
  display: inline-block;
}
.video-item a:hover {
  color: #01091e;
  background-color: #ffffff;
}

/*-- End Video --*/
/*-- Team --*/
.team-item {
  margin-bottom: 30px;
  text-align: center;
  transition: 0.5s all ease;
}
.team-item:hover {
  transform: translate(0, -10px);
}
.team-item:hover .team-top ul li:nth-child(1) {
  opacity: 1;
  top: -15px;
}
.team-item:hover .team-top ul li:nth-child(2) {
  opacity: 1;
  top: -15px;
}
.team-item:hover .team-top ul li:nth-child(3) {
  opacity: 1;
  top: -15px;
}
.team-item:hover .team-top ul li:nth-child(4) {
  opacity: 1;
  top: -15px;
}
.team-item .team-top {
  position: relative;
  margin-bottom: 15px;
}
.team-item .team-top ul {
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.team-item .team-top ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
}
.team-item .team-top ul li:nth-child(1) {
  position: relative;
  opacity: 0;
  top: 0;
  transition: 0.3s;
}
.team-item .team-top ul li:nth-child(2) {
  position: relative;
  opacity: 0;
  top: 0;
  transition: 0.4s;
}
.team-item .team-top ul li:nth-child(3) {
  position: relative;
  opacity: 0;
  top: 0;
  transition: 0.5s;
}
.team-item .team-top ul li:nth-child(4) {
  position: relative;
  opacity: 0;
  top: 0;
  transition: 0.6s;
}
.team-item .team-top ul li a {
  display: inline-block;
  color: #ffffff;
  background-color: #000000;
  text-align: center;
  border-radius: 50%;
  font-size: 15px;
}
.team-item .team-top ul li a i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.team-item .team-top ul li a:hover {
  background-color: #c1ab65;
}
.team-item .team-bottom h3 {
  color: #d8d6d6;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 5px;
}
.team-item .team-bottom span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #a4a2a2;
}

/*-- End Team --*/
/*-- Testimonial --*/
.testimonial-area {
  background-image: url("assets/img/home-one/testimonial.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.testimonial-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.9;
}
.testimonial-area .section-title {
  position: relative;
}
.testimonial-area .testimonial-item {
  position: relative;
  text-align: center;
}
.testimonial-area .testimonial-item i {
  display: block;
  color: #a7a7a0;
  font-size: 50px;
  margin-bottom: 15px;
}
.testimonial-area .testimonial-item p {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 25px;
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}
.testimonial-area .testimonial-item h3 {
  font-size: 18px;
  color: #ffffff;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 8px;
}
.testimonial-area .testimonial-item h3:before {
  position: absolute;
  content: "";
  width: 45px;
  height: 1px;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #6c6b6b;
}

/*-- End Testimonial --*/
/*-- Blog --*/
.blog-item {
  background-color: #080808;
  transition: 0.5s all ease;
  margin-bottom: 30px;
}
.blog-item:hover {
  transform: translate(0, -10px);
}
.blog-item a {
  display: block;
}
.blog-item .blog-bottom {
  padding: 25px 25px 30px 25px;
}
.blog-item .blog-bottom span {
  display: block;
  color: #c4c2c2;
  font-size: 14px;
  margin-bottom: 18px;
}
.blog-item .blog-bottom h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}
.blog-item .blog-bottom h3 a {
  color: #ffffff;
}
.blog-item .blog-bottom h3 a:hover {
  color: #c1ab65;
}
.blog-item .blog-bottom .blog-btn {
  font-weight: 700;
  color: #c7c5c5;
}
.blog-item .blog-bottom .blog-btn:hover {
  color: #c1ab65;
}

/*-- End Blog --*/
/*-- Footer --*/
footer {
  background-color: #020407;
}
footer .footer-item {
  margin-bottom: 30px;
}
footer .footer-item h3 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 25px;
}
footer .footer-item .footer-logo .footer-logo-img {
  display: block;
  margin-bottom: 25px;
}
footer .footer-item .footer-logo p {
  font-size: 14px;
  color: #989797;
  margin-bottom: 28px;
}
footer .footer-item .footer-logo ul {
  margin: 0;
  padding: 0;
}
footer .footer-item .footer-logo ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 8px;
}
footer .footer-item .footer-logo ul li:last-child {
  margin-right: 0;
}
footer .footer-item .footer-logo ul li a {
  display: block;
  border-radius: 50%;
  text-align: center;
  color: #000000;
  font-size: 18px;
  background-color: #ffffff;
}
footer .footer-item .footer-logo ul li a:hover {
  color: #ffffff;
  background-color: #c1ab65;
}
footer .footer-item .footer-logo ul li a i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
footer .footer-item .footer-instagram ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
footer .footer-item .footer-instagram ul li {
  list-style-type: none;
  display: inline-block;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 10px;
  margin-bottom: 10px;
}
footer .footer-item .footer-instagram ul li a {
  display: block;
}
footer .footer-item .footer-instagram ul li a img {
  width: 100%;
}
footer .footer-item .footer-link ul {
  margin: 0;
  padding: 0;
}
footer .footer-item .footer-link ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 10px;
}
footer .footer-item .footer-link ul li:last-child {
  margin-bottom: 0;
}
footer .footer-item .footer-link ul li a {
  display: block;
  color: #cdcccc;
  font-size: 15px;
}
footer .footer-item .footer-link ul li a:hover {
  color: #c1ab65;
}
footer .footer-item .footer-touch ul {
  margin: 0;
  padding: 0;
}
footer .footer-item .footer-touch ul li {
  list-style-type: none;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 14px;
}
footer .footer-item .footer-touch ul li:last-child {
  margin-bottom: 0;
}
footer .footer-item .footer-touch ul li i {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  font-size: 20px;
}
footer .footer-item .footer-touch ul li h4 {
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 2px;
}
footer .footer-item .footer-touch ul li a {
  display: block;
  color: #cdcccc;
  font-size: 15px;
}
footer .footer-item .footer-touch ul li a:hover {
  color: #c1ab65;
}

/*-- End Footer --*/
/*-- Copyright --*/
.copyright-area {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #06080b;
}
.copyright-area .copyright-item p {
  margin-bottom: 0;
  font-size: 15px;
  color: #ffffff;
  font-weight: 500;
}
.copyright-area .copyright-item p a {
  display: inline-block;
  font-weight: 700;
  color: #c1ab65;
}
.copyright-area .copyright-item p a:hover {
  color: #ffffff;
}

/*-- End Copyright --*/
/*----- End Home One CSS -----*/
/*----- Home Two CSS -----*/
/*-- Navbar --*/
.side-nav-two a {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  background-color: #c1ab65;
  padding: 12px 22px;
}
.side-nav-two a:hover {
  background-color: #050505;
}

/*-- End Navbar --*/
/*-- Banner --*/
.banner-area-two {
  background-image: url("assets/img/home-two/banner-main.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 1000px;
  position: relative;
}
.banner-area-two:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.6;
}
.banner-area-two .banner-content {
  position: relative;
  z-index: 1;
  max-width: 795px;
  margin-top: 30px;
}
.banner-area-two .banner-content h1 {
  font-weight: 700;
  font-size: 80px;
  margin-bottom: 0;
}
.banner-area-two .banner-content p {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 2px;
}

/*-- End Banner --*/
/*-- Service --*/
.section-title-two .sub-title {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 2px;
  margin-bottom: 15px;
}

.service-item {
  margin-bottom: 30px;
  position: relative;
}
.service-item i {
  display: inline-block;
  color: #ffffff;
  font-size: 40px;
  position: absolute;
  top: -10px;
  left: 0;
}
.service-item .service-inner {
  padding-left: 60px;
}
.service-item .service-inner h3 {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}
.service-item .service-inner p {
  margin-bottom: 0;
  color: #a9a9a9;
}

/*-- End Service --*/
/*-- Team --*/
.team-area-two {
  padding-top: 100px;
}

/*-- End Team --*/
/*-- Product --*/
.product-item {
  text-align: center;
  margin-bottom: 30px;
}
.product-item img {
  margin-bottom: 22px;
}
.product-item h3 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 15px;
}
.product-item ul {
  margin: 0;
  padding: 0;
}
.product-item ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 12px;
}
.product-item ul li:last-child {
  margin-bottom: 0;
}
.product-item ul li a {
  display: block;
  color: #ffffff;
  font-size: 18px;
}
.product-item ul li a:hover {
  color: #c1ab65;
}
.product-item p {
  padding-left: 25px;
  padding-right: 25px;
}

/*-- End Product --*/
/*----- End Home Two CSS -----*/
/*----- Home Three CSS -----*/
/*-- Banner --*/
.banner-area-three {
  background-image: url("assets/img/home-one/banner-main2.jpg");
}
.banner-area-three p {
  margin-bottom: 20px;
}
.banner-area-three h1 {
  margin-bottom: 15px;
}

/*-- End Banner --*/
/*-- Service --*/
.service-area-two {
  background-color: #ffffff;
}
.service-area-two .section-title h2 {
  color: #000000;
}
.service-area-two .service-item i {
  color: #000000;
}
.service-area-two .service-item .service-inner h3 {
  color: #212121;
}
.service-area-two .service-item .service-inner p {
  color: #212121;
}

/*-- End Service --*/
/*-- Product --*/
.product-area-two {
  background-color: #ffffff;
}
.product-area-two .section-title h2 {
  color: #000000;
}
.product-area-two .product-item h3 {
  color: #212121;
  margin-bottom: 10px;
}
.product-area-two .product-item p {
  color: #4e4e4e;
}

/*-- End Product --*/
/*-- Work --*/
.work-area-two {
  background-color: #ffffff;
}
.work-area-two .section-title h2 {
  color: #000000;
}

/*-- End Work --*/
/*-- Team --*/
.team-area-three {
  background-color: #ffffff;
}
.team-area-three .section-title h2 {
  color: #000000;
}
.team-area-three .team-item .team-bottom h3 {
  color: #212121;
}
.team-area-three .team-item .team-bottom span {
  color: #a4a2a2;
}

/*-- End Team --*/
/*-- Blog --*/
.blog-area-two {
  background-color: #ffffff;
}
.blog-area-two .section-title h2 {
  color: #000000;
}
.blog-area-two .blog-item {
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.3019607843);
}
.blog-area-two .blog-item .blog-bottom {
  padding: 25px 20px 30px;
}
.blog-area-two .blog-item .blog-bottom span {
  color: #a3a3a3;
}
.blog-area-two .blog-item .blog-bottom h3 {
  font-size: 25px;
}
.blog-area-two .blog-item .blog-bottom h3 a {
  color: #212121;
}
.blog-area-two .blog-item .blog-bottom h3 a:hover {
  color: #c1ab65;
}
.blog-area-two .blog-item .blog-bottom p {
  color: #4e4e4e;
}
.blog-area-two .blog-item .blog-bottom .blog-btn {
  color: #212121;
}
.blog-area-two .blog-item .blog-bottom .blog-btn:hover {
  color: #c1ab65;
}

/*-- End Blog --*/
/*----- End Home Three CSS -----*/
/*----- About CSS -----*/
/*-- Page Title --*/
.page-title-area {
  background-color: #020407;
}
.page-title-area .page-title-item {
  text-align: center;
  padding-top: 230px;
  padding-bottom: 145px;
}
.page-title-area .page-title-item h2 {
  font-size: 30px;
  margin-bottom: 8px;
  color: #ffffff;
  font-weight: 600;
}
.page-title-area .page-title-item ul {
  margin: 0;
  padding: 0;
}
.page-title-area .page-title-item ul li {
  list-style-type: none;
  display: inline-block;
  color: #d4d4d4;
  font-weight: 500;
}
.page-title-area .page-title-item ul li span {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #c1ab65;
  transform: rotate(45deg);
  position: relative;
  top: -2px;
  margin-left: 8px;
  margin-right: 8px;
}
.page-title-area .page-title-item ul li a {
  display: inline-block;
  color: #d4d4d4;
}
.page-title-area .page-title-item ul li a:hover {
  color: #c1ab65;
}

/*-- End Page Title --*/
/*----- End About CSS -----*/
/*----- About Me CSS -----*/
/*-- Page Title --*/
.page-title-area-two {
  background-image: url("assets/img/about-me-main.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.page-title-area-two:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.8057328985) 33%, rgba(0, 0, 0, 0) 60%);
}
.page-title-area-two .page-title-item {
  position: relative;
}

/*-- End Page Title --*/
/*-- About Me --*/
.about-me-area {
  background-color: #ffffff;
}
.about-me-area .about-me-img {
  margin-bottom: 30px;
}
.about-me-area .about-me-content h2 {
  font-weight: 700;
  font-size: 45px;
  margin-bottom: 10px;
  color: #000000;
}
.about-me-area .about-me-content ul {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.about-me-area .about-me-content ul li {
  list-style-type: none;
  display: block;
  color: #5b5b5b;
  margin-bottom: 8px;
  font-size: 17px;
  font-weight: 500;
  position: relative;
  padding-left: 15px;
}
.about-me-area .about-me-content ul li:hover:before {
  transform: rotate(0deg);
}
.about-me-area .about-me-content ul li:before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  left: 0;
  top: 11px;
  transform: rotate(45deg);
  background-color: #c1ab65;
  transition: 0.5s all ease;
}
.about-me-area .about-me-content ul li:last-child {
  margin-bottom: 0;
}
.about-me-area .about-me-content p {
  margin-bottom: 20px;
  color: #5b5b5b;
}
.about-me-area .about-me-content .about-me-inner {
  margin-bottom: 30px;
}
.about-me-area .about-me-content .about-me-inner i {
  display: inline-block;
  color: #000000;
  font-size: 40px;
  margin-bottom: 12px;
}
.about-me-area .about-me-content .about-me-inner h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 4px;
}
.about-me-area .about-me-content .about-me-inner p {
  margin-bottom: 0;
  color: #5b5b5b;
}

/*-- End About Me --*/
/*-- Work --*/
.work-area-three {
  padding-right: 15px;
  padding-left: 15px;
}
.work-area-three .work-item {
  margin-bottom: 30px;
}

/*-- End Work --*/
/*----- End About Me CSS -----*/
/*----- Work Details CSS -----*/
.work-details-item {
  margin-bottom: 30px;
}
.work-details-item .work-details-img {
  position: relative;
  margin-bottom: 25px;
}
.work-details-item .work-details-img img {
  width: 100%;
}
.work-details-item .work-details-img a {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: #c1ab65;
  color: #ffffff;
  text-align: center;
  font-size: 45px;
  width: 80px;
  height: 80px;
}
.work-details-item .work-details-img a i {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.work-details-item .work-details-img a:hover {
  background-color: #000000;
}
.work-details-item .work-details-content {
  margin-bottom: 50px;
}
.work-details-item .work-details-content ul {
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
}
.work-details-item .work-details-content ul li {
  list-style-type: none;
  display: inline-block;
  font-size: 15px;
}
.work-details-item .work-details-content ul li span {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}
.work-details-item .work-details-content ul li a {
  display: inline-block;
  color: #d5d7d7;
  margin-left: 3px;
}
.work-details-item .work-details-content ul li a:hover {
  color: #c1ab65;
}
.work-details-item .work-details-content h3 {
  margin-bottom: 8px;
  color: #ffffff;
  font-size: 35px;
  font-weight: 700;
}
.work-details-item .work-details-content p {
  margin-bottom: 15px;
}
.work-details-item .work-details-quote {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 30px 25px 30px 25px;
  position: relative;
  margin-bottom: 60px;
}
.work-details-item .work-details-quote i {
  display: inline-block;
  font-size: 40px;
  color: #000000;
  margin-bottom: 10px;
}
.work-details-item .work-details-quote p {
  color: #000000;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
  font-style: italic;
}
.work-details-item .work-details-quote .link {
  text-align: right;
}
.work-details-item .work-details-quote .link a {
  display: inline-block;
  color: #000000;
}
.work-details-item .work-details-quote .link a:hover {
  color: #c1ab65;
}
.work-details-item .work-details-search {
  margin-bottom: 50px;
}
.work-details-item .work-details-search form {
  position: relative;
}
.work-details-item .work-details-search form .form-control {
  height: 50px;
  border-radius: 3px;
  padding-left: 25px;
  font-size: 15px;
  color: #000000;
  border: 0;
}
.work-details-item .work-details-search form .form-control:focus {
  box-shadow: none;
  border: 0;
}
.work-details-item .work-details-search form .search-btn {
  position: absolute;
  top: 2px;
  right: 0;
  color: #000000;
  font-size: 24px;
  transition: 0.5s all ease;
}
.work-details-item .work-details-search form .search-btn:hover {
  color: #c1ab65;
}
.work-details-item .work-details-work {
  margin-bottom: 50px;
}
.work-details-item .work-details-work h3 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 45px;
  color: #ffffff;
  border-bottom: 1px solid rgba(221, 221, 221, 0.3490196078);
  padding-bottom: 12px;
  position: relative;
}
.work-details-item .work-details-work h3:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 3px;
  background-color: #ffffff;
  left: 0;
  bottom: -2px;
}
.work-details-item .work-details-work .work-inner {
  margin-bottom: 30px;
}
.work-details-item .work-details-work .work-inner:last-child {
  margin-bottom: 0;
}
.work-details-item .work-details-work .work-inner ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.work-details-item .work-details-work .work-inner ul li {
  list-style-type: none;
  display: inline-block;
  flex: 0 0 30%;
  max-width: 30%;
}
.work-details-item .work-details-work .work-inner ul li:last-child {
  flex: 0 0 60%;
  max-width: 60%;
  margin-left: 10px;
}
.work-details-item .work-details-work .work-inner h4 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}
.work-details-item .work-details-work .work-inner h4 a {
  display: inline-block;
  color: #ffffff;
}
.work-details-item .work-details-work .work-inner h4 a:hover {
  color: #c1ab65;
}
.work-details-item .work-details-work .work-inner span {
  display: inline-block;
}
.work-details-item .work-details-cate h3 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 20px;
  color: #ffffff;
}
.work-details-item .work-details-cate ul {
  margin: 0;
  padding: 0;
}
.work-details-item .work-details-cate ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 12px;
  position: relative;
  padding-left: 20px;
}
.work-details-item .work-details-cate ul li:hover:before {
  transform: rotate(0deg);
}
.work-details-item .work-details-cate ul li:before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  left: 0;
  top: 9px;
  transform: rotate(45deg);
  background-color: #c1ab65;
  transition: 0.5s all ease;
}
.work-details-item .work-details-cate ul li:last-child {
  margin-bottom: 0;
}
.work-details-item .work-details-cate ul li a {
  display: block;
  color: #d5d7d7;
}
.work-details-item .work-details-cate ul li a:hover {
  color: #c1ab65;
}

/*----- End Work Details CSS -----*/
/*----- Video Details CSS -----*/
.video-details-area {
  background-color: #ffffff;
}
.video-details-area .video-details-item {
  margin-bottom: 30px;
}
.video-details-area .video-details-item:last-child {
  border-right: 0;
}
.video-details-area .video-details-item .video-details-video {
  position: relative;
  margin-bottom: 20px;
}
.video-details-area .video-details-item .video-details-video img {
  width: 100%;
}
.video-details-area .video-details-item .video-details-video a {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: #c1ab65;
  color: #ffffff;
  text-align: center;
  font-size: 45px;
  width: 80px;
  height: 80px;
}
.video-details-area .video-details-item .video-details-video a i {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.video-details-area .video-details-item .video-details-video a:hover {
  background-color: #000000;
}
.video-details-area .video-details-item .video-content {
  margin-bottom: 30px;
}
.video-details-area .video-details-item .video-content .video-link {
  display: inline-block;
  color: #888888;
  font-size: 15px;
  margin-bottom: 15px;
  padding-left: 15px;
  position: relative;
}
.video-details-area .video-details-item .video-content .video-link:before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  left: 0;
  top: 9px;
  background-color: #c1ab65;
  transform: rotate(45deg);
}
.video-details-area .video-details-item .video-content .video-link:hover {
  color: #c1ab65;
}
.video-details-area .video-details-item .video-content h2 {
  font-weight: 700;
  font-size: 45px;
  color: #000000;
  margin-bottom: 10px;
}
.video-details-area .video-details-item .video-content ul {
  margin: 0;
  padding: 0;
}
.video-details-area .video-details-item .video-content ul li {
  list-style-type: none;
  display: inline-block;
  color: #000000;
  font-family: "Josefin Sans", sans-serif;
  margin-right: 20px;
}
.video-details-area .video-details-item .video-content ul li:last-child {
  margin-right: 0;
}
.video-details-area .video-details-item .video-content ul li i {
  display: inline-block;
  color: #c1ab65;
  font-size: 20px;
  margin-right: 3px;
  position: relative;
  top: 4px;
}
.video-details-area .video-details-item .video-content ul li a {
  display: inline-block;
  color: #000000;
}
.video-details-area .video-details-item .video-content ul li a:hover {
  color: #c1ab65;
}
.video-details-area .video-details-item .video-flim h3 {
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 10px;
  color: #000000;
}
.video-details-area .video-details-item .video-flim p {
  margin-bottom: 12px;
  color: #888888;
}
.video-details-area .video-details-item .video-social {
  margin-top: 25px;
}
.video-details-area .video-details-item .video-social span {
  display: inline-block;
  color: #c1ab65;
  margin-right: 10px;
}
.video-details-area .video-details-item .video-social ul {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.video-details-area .video-details-item .video-social ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 8px;
}
.video-details-area .video-details-item .video-social ul li:last-child {
  margin-right: 0;
}
.video-details-area .video-details-item .video-social ul li a {
  display: block;
  color: #ffffff;
  background-color: #000000;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
}
.video-details-area .video-details-item .video-social ul li a:hover {
  background-color: #c1ab65;
}
.video-details-area .video-details-item .video-social ul li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.video-details-area .video-details-item .video-introduce {
  padding: 45px 35px 40px;
}
.video-details-area .video-details-item .video-introduce ul {
  margin: 0;
  padding: 0;
}
.video-details-area .video-details-item .video-introduce ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 25px;
}
.video-details-area .video-details-item .video-introduce ul li:last-child {
  margin-bottom: 0;
}
.video-details-area .video-details-item .video-introduce ul li h4 {
  color: #000000;
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 20px;
}
.video-details-area .video-details-item .video-introduce ul li span {
  display: block;
  color: #888888;
  font-size: 15px;
  font-weight: 500;
}
.video-details-area .video-details-item .video-introduce.video-details-bg {
  background-color: #f9f9f9;
}
.video-details-area .video-details-item .work-details-quote {
  background-color: #ffffff;
  border-radius: 3px;
  padding: 30px 25px 30px 25px;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5019607843);
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 50px;
}
.video-details-area .video-details-item .work-details-quote i {
  display: inline-block;
  font-size: 40px;
  color: #000000;
  margin-bottom: 10px;
}
.video-details-area .video-details-item .work-details-quote p {
  color: #000000;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
  font-style: italic;
}
.video-details-area .video-details-item .work-details-quote .link {
  text-align: right;
}
.video-details-area .video-details-item .work-details-quote .link a {
  display: inline-block;
  color: #000000;
}
.video-details-area .video-details-item .work-details-quote .link a:hover {
  color: #c1ab65;
}

.week-area-two {
  background-color: #ffffff;
}
.week-area-two .section-title h2 {
  color: #000000;
}

/*----- End Video Details CSS -----*/
/*----- Blog Details CSS -----*/
.blog-details-form {
  margin-top: 50px;
}
.blog-details-form h2 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 25px;
}
.blog-details-form .form-group {
  margin-bottom: 30px;
}
.blog-details-form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
}
.blog-details-form .form-group .form-control {
  height: 50px;
  border-radius: 2px;
  padding-left: 20px;
  font-size: 15px;
  border: 1px solid #1d1c1c;
  color: #ffffff;
  background-color: #1d1c1c;
}
.blog-details-form .form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #f6f6f6;
}
.blog-details-form .form-group textarea {
  height: auto !important;
}
.blog-details-form .blog-details-btn {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 12px 22px;
  transition: 0.5s all ease;
  background-color: #ffffff;
}
.blog-details-form .blog-details-btn:hover {
  color: #ffffff;
  background-color: #c1ab65;
}

/*----- End Blog Details CSS -----*/
/*----- Partner CSS -----*/
.partner-area {
  background-color: #ffffff;
}
.partner-area .partner-item {
  margin-bottom: 30px;
  text-align: center;
}
.partner-area .partner-item:hover img {
  opacity: 1;
}
.partner-area .partner-item img {
  opacity: 0.8;
  transition: 0.5s all ease;
}

/*----- End Partner CSS -----*/
/*----- Pricing CSS -----*/
.pricing-area {
  background-color: #ffffff;
}

.pricing-item {
  background-color: #ffffff;
  margin-bottom: 30px;
  box-shadow: 0px 0px 25px 0px rgba(221, 221, 221, 0.5098039216);
  border-radius: 50px 0px 50px 0px;
}
.pricing-item .pricing-top {
  background-color: #c1ab65;
  position: relative;
  text-align: center;
  border-radius: 50px 0;
  padding-top: 25px;
  padding-bottom: 35px;
}
.pricing-item .pricing-top h3 {
  color: #ffffff;
  font-weight: 700;
  font-size: 60px;
  position: relative;
  margin-bottom: 0;
  display: inline-block;
}
.pricing-item .pricing-top span {
  display: inline-block;
  font-size: 30px;
  color: #ffffff;
}
.pricing-item .pricing-top p {
  color: #ffffff;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: -8px;
}
.pricing-item .pricing-bottom {
  text-align: center;
  padding: 0 60px;
  padding-bottom: 40px;
}
.pricing-item .pricing-bottom ul {
  margin: 0;
  padding: 0;
  margin-top: 15px;
}
.pricing-item .pricing-bottom ul li {
  list-style-type: none;
  display: block;
  color: #000000;
  display: block;
  padding: 15px 0;
  border-bottom: 1px solid rgba(102, 102, 102, 0.2);
  font-weight: 600;
}
.pricing-item .price-btn a {
  color: #c1ab65;
  padding: 10px 30px;
  border: 1px solid #c1ab65;
  border-radius: 30px;
  display: inline-block;
  margin-top: 30px;
  font-weight: 700;
}
.pricing-item .price-btn a:hover {
  color: #ffffff;
  background-color: #c1ab65;
}

/*----- End Pricing CSS -----*/
/*----- FAQ CSS -----*/
.faq-area .faq-head h2 {
  margin-bottom: 35px;
  font-weight: 600;
  font-size: 30px;
  margin-top: -7px;
  color: #ffffff;
}
.faq-area .faq-wrap {
  margin-bottom: 50px;
}
.faq-area .faq-wrap:last-child {
  margin-bottom: 30px;
}
.faq-area .accordion {
  padding-left: 0;
  margin: 0;
  padding: 0;
}
.faq-area .accordion p {
  font-size: 16px;
  display: none;
  padding: 20px 45px 15px 20px;
  margin-bottom: 0;
}
.faq-area .accordion a {
  color: #ffffff;
  font-size: 17px;
  width: 100%;
  display: block;
  cursor: pointer;
  font-weight: 600;
  padding: 15px 0 15px 18px;
  border: 1px solid #232323;
  border-radius: 8px 8px 0 0;
}
.faq-area .accordion a:hover {
  color: #c1ab65;
}
.faq-area .accordion a:after {
  position: absolute;
  right: 20px;
  content: "+";
  top: 10px;
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}
.faq-area .accordion li {
  position: relative;
  list-style-type: none;
  margin-bottom: 30px;
}
.faq-area .accordion li:first-child {
  border-top: 0;
}
.faq-area .accordion li:last-child {
  margin-bottom: 0;
}
.faq-area .accordion li a.active {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #000000;
}
.faq-area .accordion li a.active:after {
  content: "-";
  font-size: 25px;
  color: #ffffff;
}

/*----- End FAQ CSS -----*/
/*----- Error CSS -----*/
.error-item {
  height: 800px;
  text-align: center;
  margin-top: 30px;
}
.error-item h1 {
  font-size: 130px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #ffffff;
}
.error-item p {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 35px;
  color: #ffffff;
}
.error-item p i {
  display: inline-block;
}
.error-item span {
  display: block;
  color: #646464;
}
.error-item a {
  display: inline-block;
  color: #ffffff;
  background-color: #c1ab65;
  padding: 20px 45px;
  margin-top: 70px;
  font-size: 18px;
}
.error-item a:hover {
  background-color: #000000;
}

/*----- End Error CSS -----*/
/*----- Coming Soon CSS -----*/
.coming-item {
  height: 100vh;
  text-align: center;
}
.coming-item h1 {
  font-size: 75px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 20px;
  color: #ffffff;
}
.coming-item p {
  margin-bottom: 40px;
  max-width: 625px;
  margin-left: auto;
  margin-right: auto;
}
.coming-item .coming-wrap {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.coming-item .coming-wrap .coming-inner {
  text-align: center;
  background-color: #ffffff;
  padding-top: 15px;
  padding-bottom: 12px;
  margin-bottom: 30px;
}
.coming-item .coming-wrap .coming-inner h3 {
  font-size: 45px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
}
.coming-item .coming-wrap .coming-inner p {
  font-size: 16px;
  margin-bottom: 0;
  color: #383838;
}
.coming-item ul {
  margin: 0;
  padding: 0;
}
.coming-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
}
.coming-item ul li a {
  display: block;
  color: #ffffff;
  background-color: #c1ab65;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 16px;
}
.coming-item ul li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.coming-item ul li a:hover {
  background-color: #000000;
}

/*----- End Coming Soon CSS -----*/
/*----- Privacy Policy CSS -----*/
.privacy-area {
  padding-bottom: 50px;
}

.privacy-item {
  margin-bottom: 50px;
}
.privacy-item h2 {
  font-size: 26px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #ffffff;
}
.privacy-item p {
  margin-bottom: 0;
  color: #c0c0c0;
}
.privacy-item ul {
  margin: 0;
  padding: 0;
}
.privacy-item ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 18px;
  color: #c0c0c0;
}
.privacy-item ul li i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  bottom: -2px;
}
.privacy-item ul li:last-child {
  margin-bottom: 0;
}

/*----- End Privacy Policy CSS -----*/
/*----- Contact CSS -----*/
.contact-area {
  background-color: #ffffff;
}
.contact-area .contact-item {
  margin-bottom: 30px;
}
.contact-area .contact-item h2 {
  font-weight: 700;
  font-size: 45px;
  color: #000000;
  margin-bottom: 20px;
}
.contact-area .contact-item .form-group {
  margin-bottom: 30px;
}
.contact-area .contact-item .form-group .form-control {
  height: 50px;
  padding-left: 20px;
  font-size: 15px;
  border-radius: 0;
  border: 1px solid #ddd;
  background-color: rgba(241, 241, 241, 0.568627451);
}
.contact-area .contact-item .form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #000000;
}
.contact-area .contact-item .form-group ::placeholder {
  color: #000;
}
.contact-area .contact-item .form-group textarea {
  height: auto !important;
  padding-top: 15px;
}
.contact-area .contact-item .contact-btn {
  background-color: #000000;
  color: #ffffff;
  transition: 0.5s all ease;
  opacity: 1;
  border-radius: 0;
  padding: 12px 25px;
}
.contact-area .contact-item .contact-btn:hover {
  background-color: #c1ab65;
}
.contact-area .contact-item .list-unstyled {
  margin-top: 10px;
  font-size: 14px;
  color: #dc3545;
}
.contact-area .contact-item .text-danger {
  margin-top: 10px;
  font-size: 20px;
}
.contact-area .contact-item .text-success {
  margin-top: 10px;
  font-size: 20px;
}
.contact-area .contact-item .contact-address .contact-inner {
  position: relative;
  padding-left: 50px;
  margin-bottom: 25px;
}
.contact-area .contact-item .contact-address .contact-inner:last-child {
  margin-bottom: 0;
}
.contact-area .contact-item .contact-address .contact-inner .icon {
  position: absolute;
  top: 0;
  left: 0;
  color: #c1ab65;
  font-size: 30px;
}
.contact-area .contact-item .contact-address .contact-inner h4 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 2px;
  color: #000000;
}
.contact-area .contact-item .contact-address .contact-inner span {
  display: block;
  color: #5f5f5f;
}
.contact-area .contact-item .contact-address .contact-inner a {
  display: block;
  color: #5f5f5f;
}
.contact-area .contact-item .contact-address .contact-inner a:hover {
  color: #c1ab65;
}
.contact-area .contact-item .contact-address .contact-inner ul {
  margin: 0;
  padding: 0;
  margin-top: 15px;
}
.contact-area .contact-item .contact-address .contact-inner ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
}
.contact-area .contact-item .contact-address .contact-inner ul li:last-child {
  margin-right: 0;
}
.contact-area .contact-item .contact-address .contact-inner ul li a {
  display: block;
  text-align: center;
  color: #ffffff;
  background-color: #c1ab65;
  border-radius: 50%;
  font-size: 20px;
}
.contact-area .contact-item .contact-address .contact-inner ul li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.contact-area .contact-item .contact-address .contact-inner ul li a:hover {
  color: #ffffff;
  background-color: #000000;
}

.map-area #map {
  display: block;
  width: 100%;
  border: 0;
  height: 450px;
}

/*----- End Contact CSS -----*/
/*----- Preloader CSS -----*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #000;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/*----- End Preloader CSS -----*/
/*----- Back to Top CSS ------*/
#toTop {
  position: fixed;
  bottom: 30px;
  right: 0;
  cursor: pointer;
  display: none;
  z-index: 10;
}

.back-to-top-btn i {
  background: #c1ab65;
  color: #ffffff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  transition: 0.5s all ease;
  margin-right: 28px;
  box-shadow: 0px 0px 14px 0px #c1ab65;
}
.back-to-top-btn i:hover {
  background: #000;
  color: #ffffff;
  box-shadow: 0px 0px 14px 0px #000;
}

/*----- End Back to Top CSS -----*/