/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-video-camera:before { content: "\f100"; }
.flaticon-creative-idea:before { content: "\f101"; }
.flaticon-eye:before { content: "\f102"; }
.flaticon-video-camera-2:before { content: "\f103"; }
.flaticon-video-camera-1:before { content: "\f104"; }
.flaticon-theater:before { content: "\f105"; }
.flaticon-director-chair:before { content: "\f106"; }
.flaticon-video-editing:before { content: "\f107"; }
.flaticon-distribution:before { content: "\f108"; }
.flaticon-agenda { margin-top:5px; width:50px; height:50px; background-image: url('../img/icons/agenda.svg') }
.flaticon-telephone { margin-top:5px; width:50px; height:50px; background-image: url('../img/icons/telephone.svg') }
.flaticon-scenario { margin-top:5px; width:50px; height:50px; background-image: url('../img/icons/scenario.svg') }
.flaticon-service { margin-top:5px; width:50px; height:50px; background-image: url('../img/icons/service.svg') }
.flaticon-tournage { margin-top:5px; width:50px; height:50px; background-image: url('../img/icons/tournage.svg') }
.flaticon-bandedemo { margin-top:5px; width:50px; height:50px; background-image: url('../img/icons/bandedemo.svg') }